@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}


@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@media print {
  header * {
    display: none !important;
    visibility: hidden !important;
  }

  .ant-card {
    margin: 0 !important;
    box-shadow: none !important;
  }

  .ant-card-body {
    padding: 0 !important;
  }

  .ant-list {
    border: none !important;
  }

  .section-not-print,
  #section-not-print * {
    display: none !important;
    visibility: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-scroll-number {
    border: 1px solid #000000e0 !important;
  }

  .ant-scroll-number-only-unit {
    color: #000000e0 !important;
  }

  .grid-details {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  * {
    color: black !important;
    padding: 0 !important;
    border-left: 0 !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    /* padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important; */
  }


  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }
}

.ant-table-row-yellow>*:first-child {
  border-left: 8px solid #ffec3d;
}

.ant-table-row-green>*:first-child {
  border-left: 8px solid #52c41a;
}

.ant-table-row-blue>*:first-child {
  border-left: 8px solid #4096ff;
}

@media print {
  .ant-table-cell {
    border-bottom: 2px solid #ddd !important;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none !important;
  }
}

.tour-popover {
  max-width: calc(100% - 20px); /* Ensure the popover doesn't exceed screen width */
  margin: 20px; /* Add margin to avoid screen edges */
  border-radius: 8px; /* Optionally, adjust border radius */
  color: black !important;
}




